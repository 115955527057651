.seriesRelianPage {
    .seriesRelianPage__info {
        position: relative;

        .seriesRelianPage__info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .seriesRelianPage__infoContent {
            position: absolute;
            right: 10%;
            top: 40%;
            width: 24%;
            z-index: 1;
            text-align: center;

            .seriesRelianPage__infoContent-tl {
                font-family: AlimamaShuHeiTi;
                font-weight: bold;
                font-size: 42px;
                color: #000000;
            }

            .seriesRelianPage__infoContent-text {
                padding-top: 20px;

                p {
                    font-family: SourceHanSansSC;
                    font-weight: 400;
                    font-size: 20px;
                    color: #000000;
                }
            }
        }
    }

    .seriesYihuoPage__infoTwo {
        position: absolute;
        left: 0;
        bottom: 20px;
        width: 100%;
        text-align: center;

        p {
            font-family: SourceHanSansSC;
            font-size: 20px;
            color: #D5D1D0;
            line-height: 1.4;
        }
    }

    .seriesRelianPage__foot {
        margin-top: -10px;
        padding: 100px 0;
        background: url(../../assets/series/relian/info_3.png) no-repeat;
        background-size: 100% auto;

        .seriesRelianPage__product {
            padding-top: 20px;

            .seriesRelianPage__productWrap {
                width: 70%;
                margin: 0 auto;
                padding-bottom: 20px;
                overflow-x: hidden;
            }

        }

        .seriesRelianPage__footer {
            padding: 100px 0;
            text-align: center;

            .seriesRelianPage__footer-img {
                width: 80px;
                height: 108px;
            }
        }
    }
}



@media screen and (max-width:1280px) {
    .seriesRelianPage {
        .seriesRelianPage__info {
            .seriesRelianPage__infoContent {
                .seriesRelianPage__infoContent-tl {
                    font-size: 28px;
                }

                .seriesRelianPage__infoContent-text {
                    padding-top: 14px;

                    p {
                        font-size: 14px;
                    }
                }
            }

            .seriesYihuoPage__infoTwo {
                p {
                    font-size: 14px;
                }
            }
        }

        .seriesRelianPage__foot {
            padding: 60px 0;

            .seriesRelianPage__product {
                padding-top: 14px;

                .seriesRelianPage__productWrap {
                    padding-bottom: 14px;
                }

            }

            .seriesRelianPage__footer {
                padding: 60px 0;

                .seriesRelianPage__footer-img {
                    width: 54px;
                    height: 72px;
                }
            }
        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .seriesRelianPage {
        .seriesRelianPage__info {
            .seriesRelianPage__infoContent {
                .seriesRelianPage__infoContent-tl {
                    font-size: 34px;
                }

                .seriesRelianPage__infoContent-text {
                    padding-top: 16px;

                    p {
                        font-size: 16px;
                    }
                }
            }

            .seriesYihuoPage__infoTwo {
                p {
                    font-size: 16px;
                }
            }
        }
    }
}


@media screen and (min-width:2400px) {
    .seriesRelianPage {
        .seriesRelianPage__info {
            .seriesRelianPage__infoContent {
                .seriesRelianPage__infoContent-tl {
                    font-size: 50px;
                }

                .seriesRelianPage__infoContent-text {
                    padding-top: 25px;

                    p {
                        font-size: 25px;
                    }
                }
            }

            .seriesYihuoPage__infoTwo {
                bottom: 30px;

                p {
                    font-size: 25px;
                }
            }

        }

        .seriesRelianPage__foot {
            padding: 125px 0;

            .seriesRelianPage__product {
                padding-top: 25px;

                .seriesRelianPage__productWrap {
                    padding-bottom: 25px;
                }

            }

            .seriesRelianPage__footer {
                padding: 125px 0;

                .seriesRelianPage__footer-img {
                    width: 100px;
                    height: 135px;
                }
            }
        }
    }
}