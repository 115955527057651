.seriesAnyePage {
    .seriesAnyePage__info {
        position: relative;
        .seriesAnyePage__info-img {
            width: 100%;
            height: auto;
        }

        .seriesAnyePage__infoContent {
            position: absolute;
            left: 0;
            bottom: 100px;
            width: 100%;
            text-align: center;

            .seriesAnyePage__infoContent-tl {
                font-family: xiaowei;
                font-size: 42px;
                color: #FFFFFF;
            }

            .seriesAnyePage__infoContent-text {
                margin-top: 10px;

                p {
                    margin-top: 0;
                    margin-bottom: 5px;
                    font-family: SourceHanSansSC;
                    font-size: 20px;
                    color: #FFFFFF;
                    line-height: 1.5;
                }
            }
        }

        .seriesAnyePage__infoTwo {
            position: absolute;
            left: 0;
            bottom: 50px;
            width: 100%;
            text-align: center;

            p {
                font-family: SourceHanSansSC;
                font-size: 20px;
                color: #ffffff;
                line-height: 1.2;
            }
        }

    }

    .seriesAnyePage__product {
        margin-top: -8px;
        padding-top: 50px;
        padding-bottom: 780px;
        background: url(../../assets/series/anye/info_3.png) no-repeat;
        background-size: 100% auto;

        .seriesAnyePage__productWrap {
            width: 70%;
            margin: 0 auto;
            padding-bottom: 20px;
            overflow-x: hidden;
        }

    }
}


@media screen and (max-width:1280px) {
    .seriesAnyePage {
        .seriesAnyePage__info {
            .seriesAnyePage__infoContent {
                bottom: 60px;

                .seriesAnyePage__infoContent-tl {
                    font-size: 28px;
                }

                .seriesAnyePage__infoContent-text {
                    p {
                        margin-bottom: 3px;
                        font-size: 14px;
                    }
                }
            }

            .seriesAnyePage__infoTwo {
                p {
                    font-size: 14px;
                }
            }
        }

        .seriesAnyePage__product {
            padding-top: 33px;
            padding-bottom: 520px;

            .seriesAnyePage__productWrap {
                padding-bottom: 14px;
            }

        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .seriesAnyePage {
        .seriesAnyePage__info {
            .seriesAnyePage__infoContent {
                bottom: 80px;

                .seriesAnyePage__infoContent-tl {
                    font-size: 34px;
                }

                .seriesAnyePage__infoContent-text {
                    p {
                        margin-bottom: 4px;
                        font-size: 16px;
                    }
                }
            }

            .seriesAnyePage__infoTwo {
                p {
                    font-size: 16px;
                }
            }
        }

        .seriesAnyePage__product {
            padding-top: 40px;
            padding-bottom: 624px;

            .seriesAnyePage__productWrap {
                padding-bottom: 16px;
            }

        }
    }
}

@media screen and (min-width:2400px) {
    .seriesAnyePage {
        .seriesAnyePage__info {
            .seriesAnyePage__infoContent {
                bottom: 125px;

                .seriesAnyePage__infoContent-tl {
                    font-size: 52px;
                }

                .seriesAnyePage__infoContent-text {
                    p {
                        margin-bottom: 8px;
                        font-size: 25px;
                    }
                }
            }

            .seriesAnyePage__infoTwo {
                p {
                    font-size: 25px;
                }
            }
        }

        .seriesAnyePage__product {
            padding-top: 62px;
            padding-bottom: 975px;

            .seriesAnyePage__productWrap {
                padding-bottom: 25px;
            }

        }
    }
}