.seriesLiehuangPage {
    .seriesLiehuangPage__info {
        position: relative;

        .seriesLiehuangPage__info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .seriesLiehuangPage__infoContent {
            position: absolute;
            right: 20%;
            top: 28%;
            width: 30%;
            z-index: 1;
            text-align: center;

            .seriesLiehuangPage__infoContent-tl {
                font-family: PangMenZhengDao;
                font-weight: bold;
                font-size: 42px;
                color: #000000;
            }

            .seriesLiehuangPage__infoContent-text {
                padding-bottom: 10px;

                p {
                    font-family: SourceHanSansSC;
                    font-weight: 400;
                    font-size: 20px;
                    color: #000000;
                    line-height: 1;

                }
            }
        }

        .seriesLiehuangPage__infoTwo {
            position: absolute;
            left: 0;
            top: 140px;
            width: 100%;
            text-align: center;

            p {
                font-family: SourceHanSansSC;
                font-size: 20px;
                color: #000000;
                line-height: 1;
            }
        }

        .seriesLiehuangPage__infoThree {
            position: absolute;
            left: 0;
            bottom: 120px;
            width: 100%;
            text-align: center;

            p {
                font-family: SourceHanSansSC;
                font-size: 20px;
                color: #000000;
                line-height: 1;
            }
        }

        .seriesLiehuangPage__infoFour {
            position: absolute;
            left: 0;
            top: 80px;
            width: 100%;
            text-align: center;

            p {
                font-family: SourceHanSansSC;
                font-size: 20px;
                color: #000000;
                line-height: 1;
            }
        }
    }

    .seriesLiehuangPage__product {
        margin-top: -10px;
        padding-top: 120px;
        padding-bottom: 80px;
        background: url(../../assets/series/liehuang/info_3.png) no-repeat;
        background-size: 100% auto;

        .seriesLiehuangPage__productWrap {
            width: 70%;
            margin: 0 auto;
            padding-bottom: 20px;
            overflow-x: hidden;
        }
    }
}


@media screen and (max-width:1280px) {
    .seriesLiehuangPage {
        .seriesLiehuangPage__info {
            .seriesLiehuangPage__infoContent {
                .seriesLiehuangPage__infoContent-tl {
                    font-size: 28px;
                }

                .seriesLiehuangPage__infoContent-text {
                    p {
                        font-size: 13px;
                    }
                }
            }

            .seriesLiehuangPage__infoTwo {
                top: 94px;

                p {
                    font-size: 14px;
                }
            }

            .seriesLiehuangPage__infoThree {
                bottom: 80px;

                p {
                    font-size: 14px;
                }
            }

            .seriesLiehuangPage__infoFour {
                top: 54px;

                p {
                    font-size: 14px;
                }
            }

        }

        .seriesLiehuangPage__product {
            margin-top: -10px;
            padding-top: 70px;
            padding-bottom: 50px;
        }
    }
}


@media screen and (min-width:1281px) and (max-width: 1746px) {
    .seriesLiehuangPage {
        .seriesLiehuangPage__info {
            .seriesLiehuangPage__infoContent {
                .seriesLiehuangPage__infoContent-tl {
                    font-size: 34px;
                }

                .seriesLiehuangPage__infoContent-text {
                    p {
                        font-size: 15px;
                    }
                }
            }

            .seriesLiehuangPage__infoTwo {
                top: 112px;

                p {
                    font-size: 16px;
                }
            }

            .seriesLiehuangPage__infoThree {
                bottom: 96px;

                p {
                    font-size: 16px;
                }
            }

            .seriesLiehuangPage__infoFour {
                top: 64px;

                p {
                    font-size: 16px;
                }
            }
        }
        .seriesLiehuangPage__product {
            margin-top: -10px;
            padding-top: 80px;
            padding-bottom: 70px;
        }
    }
}

@media screen and (min-width:2400px) {
    .seriesLiehuangPage {
        .seriesLiehuangPage__info {
            .seriesLiehuangPage__infoContent {
                .seriesLiehuangPage__infoContent-tl {
                    font-size: 50px;
                }

                .seriesLiehuangPage__infoContent-text {
                    p {
                        font-size: 24px;
                    }
                }
            }


            .seriesLiehuangPage__infoTwo {
                top: 175px;

                p {
                    font-size: 25px;
                }
            }

            .seriesLiehuangPage__infoThree {
                bottom: 150px;

                p {
                    font-size: 25px;
                }
            }

            .seriesLiehuangPage__infoFour {
                top: 100px;

                p {
                    font-size: 25px;
                }
            }
        }
         .seriesLiehuangPage__product {
            margin-top: -10px;
            padding-top: 160px;
            padding-bottom: 90px;
        }
    }
}