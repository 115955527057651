.brandPage {
    .brandPage_info {
        position: relative;

        .brandPage_info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .brandPage__infoContent {
            position: absolute;
            left: 0;
            width: 100%;
            top: 240px;
            z-index: 1;
            text-align: center;
            .brandPage__infoContent-tl {
                font-family: SourceHanSansCN-Bold;
                font-size: 33px;
                color: #000000;
            }

            .brandPage__infoContent-en {
                font-family: CenturyGothic;
                font-size: 26px;
                color: #000000;
                transform: skew(-15deg);
            }

            .brandPage__infoContent-text {
                padding-top: 30px;

                p {
                    margin: 0;
                    font-family: SourceHanSansSC;
                    font-size: 22px;
                    color: #000000;
                    line-height: 2.4;
                    span{
                        font-family: CenturyGothic;  
                    }

                }
            }
        }
    }

    .brandPage__footer {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 140px;
        text-align: center;

        .brandPage__footerContent {
            width: 56%;
            margin: 120px auto;

            .brandPage__footerContent-text {
                margin-top: 30px;
                text-align: left;
                line-height: 1.8;
                font-family: CenturyGothic;
                font-size: 20px;
                color: #000000;
            }
        }

        .brandPage__footer-img {
            width: 80px;
            height: 108px;
        }
    }
}



@media screen and (max-width:1280px) {
    .brandPage {
        .brandPage_info {
            .brandPage__infoContent {
                top: 180px;

                .brandPage__infoContent-tl {
                    font-size: 22px;
                }

                .brandPage__infoContent-en {
                    font-size: 17px;
                }

                .brandPage__infoContent-text {
                    padding-top: 20px;
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
        .brandPage__footer {
            bottom: 90px;

            .brandPage__footerContent {
                margin: 80px auto;

                .brandPage__footerContent-text {
                    margin-top: 20px;
                    font-size: 13px;
                }
            }

            .brandPage__footer-img {
                width: 54px;
                height: 72px;
            }
        }
    }

}



@media screen and (min-width:1281px) and (max-width: 1746px) {
    .brandPage {
        .brandPage_info {
            .brandPage__infoContent {
                top: 220px;
                .brandPage__infoContent-tl {
                    font-size: 26px;
                }

                .brandPage__infoContent-en {
                    font-size: 20px;
                }

                .brandPage__infoContent-text {
                    padding-top: 24px;

                    p {
                        font-size: 18px;
                    }
                }
            }
        }
        .brandPage__footer {
            bottom: 108px;

            .brandPage__footerContent {
                margin: 96px auto;

                .brandPage__footerContent-text {
                    margin-top: 24px;
                    font-size: 16px;
                }
            }

            .brandPage__footer-img {
                width: 64px;
                height: 86px;
            }
        }
    }

}


@media screen and (min-width:2400px) {
    .brandPage {
        .brandPage_info {
            .brandPage__infoContent {
                top: 360px;

                .brandPage__infoContent-tl {
                    font-size: 42px;
                }

                .brandPage__infoContent-en {
                    font-size: 32px;
                }

                .brandPage__infoContent-text {
                    padding-top: 35px;
                    p {
                        font-size: 28px;
                    }
                }
            }
        }
        .brandPage__footer {
            bottom: 175px;
            .brandPage__footerContent {
                margin: 150px auto;

                .brandPage__footerContent-text {
                    margin-top: 38px;
                    font-size: 25px;
                }
            }

            .brandPage__footer-img {
                width: 100px;
                height: 135px;
            }
        }
    }

}
