.headerContainer {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: auto;

    .header__row {
        position: relative;
        margin: 0 80px;
        padding: 50px 0;
        display: flex;
        justify-content: space-between;
        .header__menu {
            cursor: pointer;
            .header__menu-img {
                width: 36px;
                height: 30px;
            }
        }

        .header__logo {
            position: absolute;
            width: 200px;
            left: 50%;
            margin-left: -100px;
            text-align: center;
            .header__logo-img {
                width: 80px;
                height: 108px;
            }
        }

        .header__search {
            width: 260px;
            height: 40px;
            background: url(../../assets/common/search_bg.png) no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            position: relative;

            input {
                margin-left: 5%;
                margin-top: 3%;
                width: 70%;
                height: 60%;
                background: none;
                outline: none;
                border: none;
                font-size: 16px;
                &::placeholder{
                    color: #010101;
                }
            }

            .header__search-btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 25%;
                height: 100%;
            }
        }
    }
}

@media screen and (max-width:1280px) {
    .headerContainer {
        .header__row {
            margin: 0 54px;
            padding: 33px 0;

            .header__menu {
                .header__menu-img {
                    width: 24px;
                    height: 20px;
                }
            }

            .header__logo {
                .header__logo-img {
                    width: 53px;
                    height: 72px;
                }
            }

            .header__search {
                width: 174px;
                height: 26px;

                input {
                    margin-top: 1%;
                    font-size: 12px;
                }
            }
        }
    }

}

@media screen and (min-width:2400px) {
    .headerContainer {
        .header__row {
            margin: 0 100px;
            padding: 60px 0;

            .header__menu {
                cursor: pointer;

                .header__menu-img {
                    width: 45px;
                    height: 37px;
                }
            }

            .header__logo {
                .header__logo-img {
                    width: 100px;
                    height: 135px;
                }
            }


            .header__search {
                width: 325px;
                height: 50px;

                input {
                    font-size: 20px;
                }
            }
        }
    }

}



.headerOverplay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .7);
    opacity: 1;
    transition: all .6s ease-in;
    -moz-transition: all .6s ease-in;
    -webkit-transition: all .6s ease-in;
    overflow-y: auto;
    .headerContainer__menuWrapper{
        width: 25%;
        height: 100vh;
        background: rgba(0, 0, 0, .9);
        // background: #12161B;
    }
    .headerContainer__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px 15px 0px;

        .headerContainer__menuIcon {
            cursor: pointer;
            transition: all .6s ease-in;
            .headerContainer__menu-image {
                width: 20px;
                height: 20px;
            }
            &:hover{
                transform: rotate(270deg);
            }
        }

        .headerContainer__name {
            margin-left: 20px;
            text-align: center;
            font-size: 30px;
            font-family: SourceHanSansCN-Bold;
            color: #FFFFFF;
        }
    }


    .headerContainer__menu {
        margin-top: 20px;

        .headerContainer__menuCol {
            padding: 20px 20px;
            height: auto;

            .headerContainer__menu-title {
                font-size: 30px;
                font-family: SourceHanSansCN-Bold;
                color: #FFFFFF;
            }

            .headerContainer__items {
                margin-top: 15px;
                .headerContainer__menu-itemLink {
                    display: inline-block;
                    margin-right: 15px;
                    font-size: 24px;
                    line-height: 48px;
                    color: #FFFFFF;
                    font-family: SourceHanSansSC;
                    &:hover{
                         color: #A9A9A9;
                    }
                }
            }
        }
    }
}