.shopPage {
    .shopPage_info {
        position: relative;

        .shopPage_info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }
    }

    .shopPage_foot {
        margin-top: -10px;
        padding-top: 60px;
        padding-bottom: 150px;
        background: url(../../assets/shop/info_2.png) no-repeat;
        background-size: 100% auto;

        .shopPage_title {
            margin-left: 47%;

            .shopPage_title-cn {
                font-family: SourceHanSansCN-Bold;
                font-size: 42px;
                color: #ffffff;
            }

            .shopPage_title-en {
                font-family: CenturyGothic;
                font-size: 29px;
                color: #ffffff;
            }
        }

        .shopPage_shops {
            padding-top: 80px;
            width: 50%;
            margin: 0 auto;

            .shopPage_shopsRow {
                width: 80%;
                margin-left: 20%;
                display: flex;
                justify-content: space-between;
                padding-bottom: 20px;

                .shopPage_shopsLeft {
                    width: 30%;

                    .shopPage_shopsLeft-cn {
                        font-family: SourceHanSansCN;
                        font-size: 30px;
                        color: #F1EEE7;
                    }

                    .shopPage_shopsLeft-en {
                        font-family: CenturyGothic;
                        font-size: 23px;
                        color: #F1EEE7;
                    }
                }

                .shopPage_shopsRight {
                    width: 70%;

                    .shopPage_shopItem {
                        margin-bottom: 50px;

                        .shopPage_shopItem-name {
                            font-family: SourceHanSansCN;
                            font-size: 26px;
                            color: #F1EEE7;
                        }

                        .shopPage_shopItem-address {
                            font-family: SourceHanSansCN;
                            font-size: 18px;
                            color: #F1EEE7;
                        }
                    }

                    .shopPage_qrcode {
                        padding-top: 150px;

                        .shopPage_qrcode-img {
                            width: 145px;
                            height: 145px;
                        }

                        .shopPage_qrcode-text {
                            margin-top: 10px;
                            font-family: SourceHanSansSC;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width:1280px) {
    .shopPage {
        .shopPage_info {}

        .shopPage_foot {
            padding-bottom: 100px;
            padding-top: 40px;
            .shopPage_title {

                .shopPage_title-cn {
                    font-size: 28px;
                }

                .shopPage_title-en {
                    font-size: 19px;
                }
            }

            .shopPage_shops {
                 padding-top: 54px;
                .shopPage_shopsRow {
                    padding-bottom: 14px;

                    .shopPage_shopsLeft {
                        .shopPage_shopsLeft-cn {
                            font-size: 20px;
                        }

                        .shopPage_shopsLeft-en {
                            font-size: 15px;
                        }
                    }

                    .shopPage_shopsRight {
                        .shopPage_shopItem {
                            margin-bottom: 34px;

                            .shopPage_shopItem-name {
                                font-size: 17px;
                            }

                            .shopPage_shopItem-address {
                                font-size: 12px;
                            }
                        }

                        .shopPage_qrcode {
                            padding-top: 80px;

                            .shopPage_qrcode-img {
                                width: 100px;
                                height: 100px;
                            }

                            .shopPage_qrcode-text {
                                margin-top: 6px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

}


@media screen and (min-width:1281px) and (max-width: 1746px) {
    .shopPage {
        .shopPage_info {}

        .shopPage_foot {
            padding-top: 48px;
            padding-bottom: 120px;

            .shopPage_title {

                .shopPage_title-cn {
                    font-size: 33px;
                }

                .shopPage_title-en {
                    font-size: 23px;
                }
            }

            .shopPage_shops {
                padding-top: 64px;

                .shopPage_shopsRow {
                    padding-bottom: 16px;

                    .shopPage_shopsLeft {
                        .shopPage_shopsLeft-cn {
                            font-size: 24px;
                        }

                        .shopPage_shopsLeft-en {
                            font-size: 18px;
                        }
                    }

                    .shopPage_shopsRight {
                        .shopPage_shopItem {
                            margin-bottom: 40px;

                            .shopPage_shopItem-name {
                                font-size: 20px;
                            }

                            .shopPage_shopItem-address {
                                font-size: 14px;
                            }
                        }

                        .shopPage_qrcode {
                            padding-top: 96px;

                            .shopPage_qrcode-img {
                                width: 116px;
                                height: 116px;
                            }

                            .shopPage_qrcode-text {
                                margin-top: 8px;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:2400px) {
    .shopPage {
        .shopPage_info {}

        .shopPage_foot {
            padding-top: 75px;
            padding-bottom: 180px;

            .shopPage_title {
                .shopPage_title-cn {
                    font-size: 52px;
                }

                .shopPage_title-en {
                    font-size: 36px;
                }
            }

            .shopPage_shops {
                padding-top: 100px;

                .shopPage_shopsRow {
                    padding-bottom: 25px;

                    .shopPage_shopsLeft {
                        .shopPage_shopsLeft-cn {
                            font-size: 37px;
                        }

                        .shopPage_shopsLeft-en {
                            font-size: 28px;
                        }
                    }

                    .shopPage_shopsRight {
                        .shopPage_shopItem {
                            margin-bottom: 60px;

                            .shopPage_shopItem-name {
                                font-size: 32px;
                            }

                            .shopPage_shopItem-address {
                                font-size: 22px;
                            }
                        }

                        .shopPage_qrcode {
                            padding-top: 150px;

                            .shopPage_qrcode-img {
                                width: 180px;
                                height: 180px;
                            }

                            .shopPage_qrcode-text {
                                margin-top: 12px;
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}