.footerContainer {
    padding: 100px 0;
    .footerContainer__row {
        text-align: center;

        .footerContainer__logo {
            .footerContainer__logo-img {
                width: 80px;
                height: 108px;
            }
        }

        .footerContainer__sologan {
            margin-top: 30px;
            font-family: SourceHanSansCN-Medium;
            font-size: 22px;
            color: #F1F1F1;
        }
    }
}


@media screen and (max-width:1280px) {
    .footerContainer {
        padding: 10px 0;
        .footerContainer__row {
            .footerContainer__logo {
                .footerContainer__logo-img {
                    width: 54px;
                    height: 72px;
                }
            }

            .footerContainer__sologan {
                margin-top: 20px;
                font-size: 15px;
            }
        }
    }
}


@media screen and (min-width:2400px) {
    .footerContainer {
          padding: 120px 0;
        .footerContainer__row {
            text-align: center;

            .footerContainer__logo {
                .footerContainer__logo-img {
                    width: 100px;
                    height: 135px;
                }
            }

            .footerContainer__sologan {
                margin-top: 38px;
                font-size: 28px;
            }
        }
    }

}