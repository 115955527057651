.homePage {
    position: relative;
    background: url(../../assets/home/main_bg.jpg) no-repeat;
    background-size: cover;

    .homePage__mainBg {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: auto;
    }

    .top-empty {
        height: 1563px;
    }

    .homePage__mainWrap {
   

        .homePage__videoWrap {
            display: block;
        }

        .homePage__solganRow {
            padding: 20px 0 50px 0;
            display: flex;
            justify-content: space-between;

            .homePage__solganLeft {
                font-family: SourceHanSansSC;
                font-size: 20px;

                .homePage__solganLeft-b {
                    margin-right: 10px;
                    font-family: SourceHanSansCN-Bold;
                    font-size: 28px;
                }
            }

            .homePage__solganRight {
                cursor: pointer;

                .homePage__solganRight-span {
                    font-family: SourceHanSansSC;
                    font-size: 20px;
                    vertical-align: middle;
                }

                .homePage__solganRight-img {
                    margin-left: 10px;
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                }
            }
        }
    }

    .homePage__footer {
        padding: 60px 0 130px 0;
    }
}

@media screen and (max-width:1280px) {
    .homePage {
        .top-empty {
            height: 1042px;
        }

        .homePage__mainWrap {
            .homePage__solganRow {
                .homePage__solganLeft {
                    font-size: 16px;

                    .homePage__solganLeft-b {
                        font-size: 22px;
                    }
                }

                .homePage__solganRight {
                    .homePage__solganRight-span {
                        font-size: 14px;
                    }

                    .homePage__solganRight-img {
                        margin-left: 6px;
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
    }
}


@media screen and (min-width:1281px) and (max-width: 1746px) {
    .homePage {
        .top-empty {
            height: 1250px;
        }

        .homePage__mainWrap {
            .homePage__solganRow {
                .homePage__solganLeft {
                    font-size: 14px;

                    .homePage__solganLeft-b {
                        font-size: 18px;
                    }
                }

                .homePage__solganRight {
                    .homePage__solganRight-span {
                        font-size: 16px;
                    }

                    .homePage__solganRight-img {
                        margin-left: 8px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        .homePage__footer {
            padding: 40px 0 85px 0;
        }
    }

}


@media screen and (min-width:2400px) {
    .homePage {
        .top-empty {
            height: 1953px;
        }

        .homePage__mainWrap {
            .homePage__solganRow {
                .homePage__solganLeft {
                    font-size: 25px;

                    .homePage__solganLeft-b {
                        font-size: 35px;
                    }
                }

                .homePage__solganRight {
                    .homePage__solganRight-span {
                        font-size: 25px;
                    }

                    .homePage__solganRight-img {
                        margin-left: 12px;
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }

        .homePage__footer {
            padding: 75px 0 160px 0;
        }
    }
}