.seriesQianjiPage {
    .seriesQianjiPage__info {
        position: relative;

        .seriesQianjiPage__info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }
    }

    .seriesQianjiPage__middleBg {
        margin-top: -10px;
        padding-bottom: 20px;
        background: url(../../assets/series/qianji/info_2.png) no-repeat;
        background-size: cover;
        .seriesQianjiPage__infoContent {
            padding: 120px 0;
            text-align: center;

            .seriesQianjiPage__infoContent-tl {
                font-family: xiaowei;
                font-size: 42px;
                color: #FFFFFF;
            }

            .seriesQianjiPage__infoContent-text {
                margin-top: 10px;

                p {
                    font-family: SourceHanSansSC;
                    font-size: 20px;
                    color: #FFFFFF;
                    line-height: 1;
                }
            }
        }

        .seriesQianjiPage__product {
            .seriesQianjiPage__productWrap {
                width: 70%;
                margin: 0 auto;
                padding-bottom: 20px;
                overflow-x: hidden;
            }
        }
    }

    .seriesQianjiPage__infoThree {
        position: absolute;
        left: 0;
        top: 100px;
        width: 100%;
        text-align: center;

        p {
            font-family: SourceHanSansSC;
            font-size: 20px;
            color: #ffffff;
            line-height: 1.2;
        }
    }

}


@media screen and (max-width:1280px) {
    .seriesQianjiPage {
        .seriesQianjiPage__middleBg {
            .seriesQianjiPage__infoContent {
                padding: 80px 0;

                .seriesQianjiPage__infoContent-tl {
                    font-size: 28px;
                }

                .seriesQianjiPage__infoContent-text {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }

        .seriesQianjiPage__infoThree {
            p {
                font-size: 14px;
            }
        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .seriesQianjiPage {
        .seriesQianjiPage__middleBg {
            .seriesQianjiPage__infoContent {
                padding: 96px 0;

                .seriesQianjiPage__infoContent-tl {
                    font-size: 34px;
                }

                .seriesQianjiPage__infoContent-text {
                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .seriesQianjiPage__infoThree {
            p {
                font-size: 16px;
            }
        }
    }
}


@media screen and (min-width:2400px) {
    .seriesQianjiPage {
        .seriesQianjiPage__middleBg {
            .seriesQianjiPage__infoContent {
                padding: 150px 0;

                .seriesQianjiPage__infoContent-tl {
                    font-size: 52px;
                }

                .seriesQianjiPage__infoContent-text {
                    p {
                        font-size: 25px;
                    }
                }
            }
        }

        .seriesQianjiPage__infoThree {
            p {
                font-size: 25px;
            }
        }
    }
}