.searchEmptyPage {
    .searchEmptyPage_info {
        position: relative;
        .searchEmptyPage_info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }
    }
  .searchEmptyPage_text{
    position: absolute;
    left: 0;
    top: 40%;
    width: 100%;
    text-align: center;
    font-family: SourceHanSansSC;
    font-size: 30px;
    color: #151515;
  }
}
