  .productList {
      display: flex;
      gap: 10px;
      padding-bottom: 30px;
      transition: all .5s;
      .productItem {
          position: relative;
          text-align: center;
          cursor: pointer;
          .productItem-name {
              padding-bottom: 20px;
              font-family: SourceHanSansSC;
              font-size: 20px;
              color: #FFFFFF;
          }
          .productItem-img {
              width: 328px;
              height: 328px;
              cursor: pointer;
          }
          .productItem-bigImg{
             opacity: 0;
             position: absolute;
             bottom: 0;
             left: 0;
             z-index: 1000;
             transition: transform .5s;
          }
          &:hover{
             .productItem-bigImg{
                  opacity: 1;
                  transform: scale(1.05);
              }
          }
      }
  }

  @media screen and (max-width:1280px) {
      .productList {
          gap: 6px;
          .productItem {
              .productItem-name {
                  padding-bottom: 14px;
                  font-size: 14px;
              }
          }
      }
  }

@media screen and (min-width:1281px) and (max-width: 1746px) {
      .productList {
          gap: 8px;
          .productItem {
              .productItem-name {
                  padding-bottom: 16px;
                  font-size: 16px;
              }
          }
      }
  }


@media screen and (min-width:2400px) {
      .productList {
          gap: 12px;
          .productItem {
              .productItem-name {
                  padding-bottom: 25px;
                  font-size: 25px;
              }
          }
      }
  }