.homeBrand {
    .homeBrand__info {
        position: relative;

        .homeBrand__info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .homeBrand__infoText {
            position: absolute;
            top: 70px;
            left: 25%;
            right: 25%;

            .homeBrand__infoText-tl {
                font-family: CenturyGothic;
                font-size: 30px;
                color: #000000;
            }

            .homeBrand__infoText-text {
                margin-top: 10px;
                font-family: SourceHanSansSC;
                font-size: 18px;
                color: #000000;
                line-height: 2;

                span {
                    font-family: CenturyGothic;
                }
            }
        }

        .homeBrand__infoMore {
            position: absolute;
            bottom: 100px;
            left: 25%;

            .homeBrand__infoMore-span {
                vertical-align: middle;
                font-family: SourceHanSansSC;
                font-size: 18px;
                color: #000000;
            }

            .homeBrand__infoMore-arrow {
                vertical-align: middle;
                margin-left: 4px;
                display: inline-block;
                width: 18px;
                height: 18px;
                background: url(../../../../assets/home/arrow_right_black.png) no-repeat;
                background-size: 100% 100%;
            }
        }

    }
}

@media screen and (max-width:1280px) {
    .homeBrand {
        .homeBrand__info {
            .homeBrand__infoText {
                top: 60px;

                .homeBrand__infoText-tl {
                    font-size: 20px;
                }

                .homeBrand__infoText-text {
                    margin-top: 6px;
                    font-size: 12px;
                }
            }

            .homeBrand__infoMore {
                bottom: 60px;

                .homeBrand__infoMore-span {
                    font-size: 12px;
                }

                .homeBrand__infoMore-arrow {
                    margin-left: 4px;
                    width: 15px;
                    height: 15px;
                }
            }

        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .homeBrand {
        .homeBrand__info {
            .homeBrand__infoText {
                top: 80px;

                .homeBrand__infoText-tl {
                    font-size: 24px;
                }

                .homeBrand__infoText-text {
                    margin-top: 8px;
                    font-size: 15px;
                }
            }

            .homeBrand__infoMore {
                bottom: 80px;

                .homeBrand__infoMore-span {
                    font-size: 15px;
                }
                 .homeBrand__infoMore-arrow {
                    margin-left: 4px;
                    width: 18px;
                    height: 18px;
                }
            }

        }
    }
}


@media screen and (min-width:2400px) {
    .homeBrand {
        .homeBrand__info {
            .homeBrand__infoText {
                top: 90px;

                .homeBrand__infoText-tl {
                    font-size: 38px;
                }

                .homeBrand__infoText-text {
                    margin-top: 15px;
                    font-size: 22px;
                }
            }

            .homeBrand__infoMore {
                bottom: 130px;

                .homeBrand__infoMore-span {
                    font-size: 22px;
                }
                 .homeBrand__infoMore-arrow {
                    margin-left: 4px;
                    width: 22px;
                    height: 22px;
                }
            }

        }
    }
}