.contactPage {
    .contactPage_info {
        position: relative;
        .contactPage_info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }
    }

    .contactPage__code {
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        text-align: center;
        .contactPage__codeRow {
            margin: 0 auto;
            width: 820px;
            display: flex;
            justify-content: space-between;

            .contactPage__codeCol {
                text-align: center;

                .contactPage__codeCol-img {
                    width: 134px;
                    height: 134px;
                }

                .contactPage__codeCol-name {
                    margin-top: 15px;
                    font-family: SourceHanSansSC;
                    font-size: 19px;
                    color: #151515;
                }

                .contactPage__codeCol-desc {
                    font-family: CenturyGothic;
                    font-size: 13px;
                    color: #151515;
                }
            }
        }
    }
}



@media screen and (max-width:1280px) {
    .contactPage {
        .contactPage__code {
            .contactPage__codeRow {
                width: 546px;

                .contactPage__codeCol {
                    .contactPage__codeCol-img {
                        width: 88px;
                        height: 88px;
                    }

                    .contactPage__codeCol-name {
                        margin-top: 10px;
                        font-size: 13px;
                    }

                    .contactPage__codeCol-desc {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}



@media screen and (min-width:1281px) and (max-width: 1746px) {
    .contactPage {
        .contactPage__code {
            .contactPage__codeRow {
                width: 660px;

                .contactPage__codeCol {
                    .contactPage__codeCol-img {
                        width: 107px;
                        height: 107px;
                    }

                    .contactPage__codeCol-name {
                        margin-top: 12px;
                        font-size: 15px;
                    }

                    .contactPage__codeCol-desc {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}


@media screen and (min-width:2400px) {
    .contactPage {
        .contactPage__code {
            .contactPage__codeRow {
                width: 1080px;

                .contactPage__codeCol {
                    .contactPage__codeCol-img {
                        width: 167px;
                        height: 167px;
                    }

                    .contactPage__codeCol-name {
                        margin-top: 18px;
                        font-size: 24px;
                    }

                    .contactPage__codeCol-desc {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}