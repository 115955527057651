.SliderContainer {
    .horizontal-slider {
        width: 100%;
        height: 15px;
        margin: auto;
        background: url(../../assets/common/bar.png) no-repeat;
        background-size: cover;
        background-position: -50px;
    }

    .slider-thumb {
        cursor: pointer;
        position: absolute;
        z-index: 100;
        display: block;
        top: -20px;
        outline: none;
        width: 50px;
        height: 58px;

        &.control__relian {
            background: url(../../assets/common/control_relian.png) no-repeat;
            background-size: cover;
        }

        &.control_anye {
            top: -14px;
            width: 50px;
            height: 44px;
            background: url(../../assets/common/control_anye.png) no-repeat;
            background-size: cover;
        }

        &.control_qianji {
            top: -14px;
            width: 50px;
            height: 40px;
            background: url(../../assets/common/control_qianji.png) no-repeat;
            background-size: cover;
        }

        &.control_yihuo {
            top: -20px;
            width: 49px;
            height: 54px;
            background: url(../../assets/common/control_yihuo.png) no-repeat;
            background-size: cover;
        }

        &.control_liehuang {
            top: -16px;
            width: 48px;
            height: 48px;
            background: url(../../assets/common/control_liehuang.png) no-repeat;
            background-size: cover;
        }
    }

    .slider-track {
        position: relative;
        background: red;
    }
}


@media screen and (max-width:1280px) {
    .SliderContainer {
        .slider-thumb {
            top: -14px;
            width: 34px;
            height: 38px;
        }
    }
}

@media screen and (min-width:2400px) {
    .SliderContainer {
        .horizontal-slider {
            height: 18px;
            background: url(../../assets/common/bar.png) no-repeat;
            background-size: 120% 100%;
            background-position: -50px;
        }
    }
}