.homePage__fourRow {
    margin-top: 40px;
    display: flex;
    gap: 6px;
     &>div{
        flex: 1;
     }
    .homePage__fourCol {
        .homePage__fourCol-imgWrap {
            .homePage__fourCol-img {
                width: 100%;
                height: auto;
            }
        }

        .homePage__fourCol-textWrap {
            padding: 5px 0;
            text-align: center;
            color: #F1F1F1;

            .homePage__fourCol-title {
                font-family: SourceHanSansCN-Bold;
                font-size: 24px;

            }

            .homePage__fourCol-desc {
                font-family: SourceHanSansSC;
                font-size: 18px;
            }

            .homePage__fourCol-link {
                position: relative;
                display: block;
                margin-top: 8px;
                font-family: SourceHanSansSC;
                font-size: 18px;
                color: #A9A9A9;
                cursor: pointer;

                &::after {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: -6px;
                    width: 60px;
                    height: 1px;
                    margin-left: -30px;
                    background: #A9A9A9;
                }
            }
        }
    }
}


@media screen and (max-width:1280px) {
    .homePage__fourRow {
        margin-top: 25px;

        .homePage__fourCol {
            .homePage__fourCol-textWrap {
                padding: 3px 0;

                .homePage__fourCol-title {
                    font-size: 16px;

                }

                .homePage__fourCol-desc {
                    font-size: 12px;
                }

                .homePage__fourCol-link {
                    margin-top: 6px;
                    font-size: 12px;

                    &::after {
                        display: block;
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: -4px;
                        width: 48px;
                        height: 1px;
                        margin-left: -24px;
                        background: #A9A9A9;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .homePage__fourRow {
        margin-top: 30px;
        gap: 8px;
        .homePage__fourCol {
            .homePage__fourCol-textWrap {
                padding: 4px 0;

                .homePage__fourCol-title {
                    font-size: 20px;

                }

                .homePage__fourCol-desc {
                    font-size: 15px;
                }

                .homePage__fourCol-link {
                    margin-top: 8px;
                    font-size: 15px;

                    &::after {
                        display: block;
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: -5px;
                        width: 56px;
                        height: 1px;
                        margin-left: -28px;
                        background: #A9A9A9;
                    }
                }
            }
        }
    }

}


@media screen and (min-width:2400px) {
      .homePage__fourRow {
        margin-top: 50px;
        gap: 10px;
        .homePage__fourCol {
          
            .homePage__fourCol-textWrap {
                padding: 6px 0;

                .homePage__fourCol-title {
                    font-size: 30px;

                }

                .homePage__fourCol-desc {
                    font-size: 22px;
                }

                .homePage__fourCol-link {
                    margin-top: 12px;
                    font-size: 22px;

                    &::after {
                        display: block;
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: -7px;
                        width: 84px;
                        height: 1px;
                        margin-left: -42px;
                        background: #A9A9A9;
                    }
                }
            }
        }
    }
}