.seriesYihuoPage {
    .seriesYihuoPage__info {
        position: relative;

        .seriesYihuoPage__info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .seriesYihuoPage__infoContent {
            position: absolute;
            right: 15%;
            top: 35%;
            width: 24%;
            z-index: 1;
            text-align: right;

            .seriesYihuoPage__infoContent-tl {
                .seriesYihuoPage__infoContent-tlImg {
                    width: 232px;
                    height: auto;
                }
            }

            .seriesYihuoPage__infoContent-text {
                padding-top: 10px;

                p {
                    font-family: SourceHanSansSC;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 1;
                    color: #000000;
                }
            }
        }

        .seriesYihuoPage__middleTitle {
            position: absolute;
            left: 50%;
            bottom: 60px;
            width: 800px;
            margin-left: -400px;
            text-align: center;
            font-family: WenCang;
            font-size: 48px;
            color: #969696;
        }

        .seriesYihuoPage__infoThree {
            position: absolute;
            left: 0;
            bottom: 10px;
            width: 100%;
            text-align: center;

            p {
                font-family: SourceHanSansSC;
                font-size: 20px;
                color: #969696;
                line-height: 1.4;
            }
        }
    }

    .seriesYihuoPage__infoFourWrap {
        background: url(../../assets/series/yihuo/info_4.jpg) no-repeat;
        background-size: 100% auto;
        padding: 100px 0 50px 0;

        .seriesYihuoPage__items {
            display: flex;
            gap: 30px;

            .seriesYihuoPage__itemCol {
                .seriesYihuoPage__itemCol-img {
                    width: 100%;
                    height: auto;
                }

                .seriesYihuoPage__itemComtent {
                    .seriesYihuoPage__itemTl {
                        margin-top: 10px;
                        font-family: WenCang;
                        font-size: 28px;
                        color: #969696;
                    }

                    .seriesYihuoPage__itemDesc {
                        font-family: SourceHanSansSC;
                        font-size: 15px;
                        color: #969696;
                    }
                }
            }
        }
    }

    .seriesYihuoPage__infoFive {
        position: absolute;
        left: 0;
        bottom: 100px;
        width: 100%;
        text-align: center;
        p {
            font-family: SourceHanSansSC;
            font-size: 20px;
            color: #969696;
            line-height: 1;
        }
    }
    .seriesYihuoPage__infoSeven{
        position: absolute;
        left: 0;
        top: 100px;
        width: 100%;
        text-align: center;
        p {
            font-family: SourceHanSansSC;
            font-size: 20px;
            color: #969696;
            line-height: 1.4;
        }
    }

    .seriesYihuoPage__product {
        padding-top: 30px;
        padding-bottom: 30px;
        background: url(../../assets/series/yihuo/info_6.jpg) no-repeat;
        background-size: 100% auto;
        .seriesYihuoPage__productWrap {
            width: 70%;
            margin: 0 auto;
            padding-bottom: 20px;
            overflow-x: hidden;
        }
    }

}


@media screen and (max-width:1280px) {
    .seriesYihuoPage {
        .seriesYihuoPage__info {
            .seriesYihuoPage__infoContent {
                .seriesRelianPage__infoContent-tl {
                    .seriesYihuoPage__infoContent-tlImg {
                        width: 154px;
                        height: auto;
                    }
                }

                .seriesYihuoPage__infoContent-text {
                    padding-top: 6px;

                    p {
                        font-size: 13px;
                    }
                }
            }

            .seriesYihuoPage__middleTitle {
                bottom: 40px;
                width: 600px;
                margin-left: -300px;
                font-size: 32px;
            }

            .seriesYihuoPage__infoThree {
                p {
                    font-size: 14px;
                }
            }

            .seriesYihuoPage__infoFourWrap {
                padding: 66px 0 33px 0;
                .seriesYihuoPage__items {
                    display: flex;
                    gap: 10px;
                    .seriesYihuoPage__itemCol {
                        .seriesYihuoPage__itemComtent {
                            .seriesYihuoPage__itemTl {
                                margin-top: 6px;
                                font-size: 18px;
                            }

                            .seriesYihuoPage__itemDesc {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .seriesYihuoPage__infoFive {
                bottom: 66px;

                p {
                    font-size: 14px;
                }
            }
            .seriesYihuoPage__infoSeven {
                top: 66px;
                p {
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .seriesYihuoPage {
        .seriesYihuoPage__info {
            .seriesYihuoPage__infoContent {
                .seriesRelianPage__infoContent-tl {
                    .seriesYihuoPage__infoContent-tlImg {
                        width: 185px;
                        height: auto;
                    }
                }

                .seriesYihuoPage__infoContent-text {
                    padding-top: 8px;

                    p {
                        font-size: 15px;
                    }
                }
            }

            .seriesYihuoPage__middleTitle {
                bottom: 48px;
                width: 800px;
                margin-left: -400px;
                font-size: 38px;
            }

            .seriesYihuoPage__infoThree {
                p {
                    font-size: 16px;
                }
            }
             .seriesYihuoPage__infoFourWrap {
                padding: 80px 0 40px 0;
                .seriesYihuoPage__items {
                    display: flex;
                    gap: 15px;
                    .seriesYihuoPage__itemCol {
                        .seriesYihuoPage__itemComtent {
                            .seriesYihuoPage__itemTl {
                                margin-top: 8px;
                                font-size: 22px;
                            }
                            .seriesYihuoPage__itemDesc {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }

            .seriesYihuoPage__infoFive {
                bottom: 80px;

                p {
                    font-size: 16px;
                }
            }
            .seriesYihuoPage__infoSeven {
                top: 80px;

                p {
                    font-size: 16px;
                }
            }

        }
    }
}


@media screen and (min-width:2400px) {
    .seriesYihuoPage {
        .seriesYihuoPage__info {
            .seriesYihuoPage__infoContent {
                .seriesRelianPage__infoContent-tl {
                    .seriesYihuoPage__infoContent-tlImg {
                        width: 290px;
                        height: auto;
                    }
                }

                .seriesYihuoPage__infoContent-text {
                    padding-top: 12px;

                    p {
                        font-size: 22px;
                    }
                }
            }

            .seriesYihuoPage__middleTitle {
                bottom: 75px;
                width: 1000px;
                margin-left: -500px;
                font-size: 60px;
            }

            .seriesYihuoPage__infoThree {
                p {
                    font-size: 25px;
                }
            }
            .seriesYihuoPage__infoFourWrap {
                padding: 125px 0 60px 0;
                .seriesYihuoPage__items {
                    display: flex;
                    gap: 36px;
                    .seriesYihuoPage__itemCol {
                        .seriesYihuoPage__itemComtent {
                            .seriesYihuoPage__itemTl {
                                margin-top: 12px;
                                font-size: 35px;
                            }
                            .seriesYihuoPage__itemDesc {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            .seriesYihuoPage__infoFive {
                bottom: 125px;

                p {
                    font-size: 25px;
                }
            }
            .seriesYihuoPage__infoSeven {
                top: 125px;

                p {
                    font-size: 25px;
                }
            }
        }
    }
}